import { storyblokEditable, SbBlokData } from "@storyblok/react";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import Image from "next/image";
import {
  MARK_LINK,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_UL,
  NODE_IMAGE,
  render,
  NODE_HEADING,
  NODE_LI,
} from "storyblok-rich-text-react-renderer";
import { useState, useEffect } from "react";
import Cta from "../Cta";

interface sbBlok extends SbBlokData {
  [key: string]: any;
}

interface TextComponentProps {
  blok: sbBlok;
}

const TextComponent = ({ blok }: TextComponentProps) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);
  if (isFirstRender) return <div />;

  return (
    <GridWrapper
      padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
      padding_bottom={
        blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
      }
    >
      <GridElement
        gridLayout={[
          [961, 3, 12],
          [1401, 9, 16],
        ]}
      >
        <div {...storyblokEditable(blok)}>
          <div style={{ textAlign: blok.title_alignment }}>
            {render(blok?.title, {
              nodeResolvers: {
                [NODE_PARAGRAPH]: (children) => <p>{children}</p>,
                [NODE_HEADING]: (
                  children,
                  props: { level: 1 | 2 | 3 | 4 | 5 | 6 }
                ) => {
                  if (props?.level === 1) return <h1>{children}</h1>;

                  if (props?.level === 2) return <h2>{children}</h2>;

                  return <h3>{children}</h3>;
                },
              },
            })}
          </div>
          <div style={{ textAlign: blok.content_alignment }}>
            {render(blok?.content, {
              nodeResolvers: {
                [NODE_PARAGRAPH]: (children) => <p>{children}</p>,
                [NODE_UL]: (children) => (
                  <ul className="pl-16 list-disc">{children}</ul>
                ),
                [NODE_OL]: (children) => (
                  <ol className="pl-16 list-decimal">{children}</ol>
                ),
                [NODE_LI]: (children) => (
                  <li className="mb-6 last:mb-0">{children}</li>
                ),
                [NODE_IMAGE]: (_, props) => (
                  <div>
                    <Image
                      src={props.src as string}
                      alt={props.alt}
                      layout="fixed"
                      width={300}
                      height={150}
                      objectFit="contain"
                    />
                  </div>
                ),
              },
              markResolvers: {
                [MARK_LINK]: (children, props) => {
                  return (
                    <a
                      href={props?.href}
                      target={props?.target}
                      style={{ color: "var(--tm-c-brand)" }}
                    >
                      {children}
                    </a>
                  );
                },
              },
            })}
          </div>
          {(blok.cta_button?.id || blok.cta_button?.url) && (
            <Cta
              name={
                blok.cta_button_name?.length > 0
                  ? blok.cta_button_name
                  : blok.cta_button?.story?.name
              }
              href={blok.cta_button?.url}
              fullSlug={blok.cta_button?.story?.full_slug}
              style={{ margin: "6rem auto 0" }}
            />
          )}
        </div>
      </GridElement>
    </GridWrapper>
  );
};

export default TextComponent;
