import Image from "next/image";
import { sbBlok } from "../../types/sb-types";
import styles from "./Header.module.css";
import { storyblokEditable } from "@storyblok/react";
import GridWrapper from "../GridWrapper/GridWrapper";
import GridElement from "../GridElement/GridElement";
import Cta from "../Cta/Cta";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TournamentLeaderboardWidget } from "../../queries/tournament-leaderboard";
import { useWindowSize } from "../../hooks/useWindowSize";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { NODE_PARAGRAPH, render } from "storyblok-rich-text-react-renderer";
import { GOLF_PORTAL_API } from "../../utils/constants";

const Header = ({ blok }: sbBlok) => {
  const {
    description_button,
    description_button_variant,
    image,
    mobile_image,
    leaderboard_title,
    leaderboard_btn_title,
    leaderboard_btn_url,
    leaderboard_logo,
    leaderboard_tournament_id,
    logo,
    sub_title,
    title,
  } = blok;

  const { data, isLoading: isLeaderboardDataLoading } = useQuery({
    queryKey: ["tournament_leaderboard_widget"],
    queryFn: () => {
      return axios.post(GOLF_PORTAL_API, {
        query: TournamentLeaderboardWidget,
        variables: {
          tournamentId: leaderboard_tournament_id,
          skip: 0,
          take: 5,
        },
      });
    },
    enabled: !!leaderboard_title,
  });
  const leaderboardItems = data?.data?.data?.node?.leaderboard?.records?.items;

  const { isMobile } = useWindowSize();

  const variants = {
    initial: {
      opacity: isMobile ? 1 : 0.5,
      transform: `translate(0rem, 4rem)`,
    },
    view: {
      opacity: 1,
      transform: "translate(0rem, 0rem)",
    },
  };

  const transition = {
    ease: "linear",
    duration: 0.8,
  };

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, []);
  if (isFirstRender) return <div />;

  return (
    <div className={styles.headerContainer} {...storyblokEditable(blok)}>
      <div className={styles.headerBackgroundWrapper}>
        <div className={styles.headerBackground}>
          {isMobile && mobile_image?.filename ? (
            <Image
              src={mobile_image?.filename}
              alt={mobile_image?.alt}
              layout="fill"
              objectFit="cover"
            />
          ) : (
            image?.filename && (
              <Image
                src={image.filename}
                alt={image.alt}
                layout="fill"
                objectFit="cover"
                quality={100}
              />
            )
          )}
        </div>
      </div>
      <GridWrapper
        className={styles.headerGridWrapper}
        gridClassName={`${!leaderboard_title ? styles.headerGrid : ""}`}
      >
        <GridElement
          gridLayout={[
            [0, 1, 4, 1],
            [961, 2, 14, 1],
            [1401, 4, 26, 1],
          ]}
          className={styles.headerGridElementContent}
        >
          <motion.div
            className={styles.headerContentWrapper}
            variants={variants}
            transition={transition}
            whileInView="view"
            initial="initial"
          >
            <div className={styles.headerContent}>
              {logo?.filename && (
                <div className={styles.headerLogo}>
                  <Image
                    src={logo.filename}
                    alt={logo.alt}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
              )}
              {title?.content?.length > 0 && (
                <div className={styles.headerTitle}>
                  {render(title, {
                    nodeResolvers: {
                      [NODE_PARAGRAPH]: (children) => <h1>{children}</h1>,
                    },
                  })}
                </div>
              )}
              {sub_title && (
                <p
                  className={`${styles.headerSubTitle}${
                    !description_button?.length
                      ? " " + styles.headerSubTitleMargin
                      : ""
                  }`}
                >
                  {sub_title}
                </p>
              )}
              {description_button?.length > 0 && (
                <Cta
                  href={description_button[0]?.link?.url}
                  fullSlug={description_button[0]?.link?.story?.full_slug}
                  name={description_button[0]?.name}
                  variant={description_button_variant}
                  className={styles.headerBtn}
                />
              )}
            </div>
            {leaderboard_title && (
              <div className={styles.headerLeaderboard}>
                {leaderboard_logo?.filename && (
                  <div className={styles.headerLeaderboardLogo}>
                    <Image
                      src={leaderboard_logo.filename}
                      alt={leaderboard_logo.alt}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>
                )}
                <h3 className={styles.headerLeaderboardTitle}>
                  {leaderboard_title}
                </h3>
                {isLeaderboardDataLoading && (
                  <div role="table" className={styles.leaderboardTable}>
                    <div
                      role="thead"
                      className={`${styles.leaderboardTableHeader} ${styles.leaderboardTableLoadingRow}`}
                    >
                      <div role="th">Pos</div>
                      <div role="th">Player</div>
                      <div role="th">Thru</div>
                      <div role="th">Score</div>
                    </div>
                    <div role="tbody" className={styles.leaderboardTableBody}>
                      {Array.from(Array(5)).map((_item: any, i: number) => (
                        <div
                          role="tr"
                          className={`${styles.leaderboardTableRow} ${styles.leaderboardTableLoadingRow}`}
                          key={`tr-key-${i}`}
                        >
                          {Array.from(Array(4)).map((_item: any, i: number) => (
                            <div
                              role="td"
                              className={styles.loadingItem}
                              key={`td-key-${i}`}
                            >
                              <span className={styles.tableRowLoading} />
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {!isLeaderboardDataLoading && leaderboardItems?.length > 0 && (
                  <div role="table" className={styles.leaderboardTable}>
                    <div role="thead" className={styles.leaderboardTableHeader}>
                      <div role="th">Pos</div>
                      <div role="th">Player</div>
                      <div role="th">Thru</div>
                      <div role="th">Score</div>
                    </div>
                    <div role="tbody" className={styles.leaderboardTableBody}>
                      {leaderboardItems.map((item: any, n: number) => {
                        const isPosFirstKind =
                          n > 0 &&
                          item.total.pos !== leaderboardItems[n - 1].total.pos;
                        return (
                          <div
                            role="tr"
                            className={styles.leaderboardTableRow}
                            key={item.id}
                          >
                            <div role="td">
                              {item.total.state.toLowerCase() ===
                                "did_not_finish" ||
                              item.total.state.toLowerCase() === "no_show"
                                ? "DNF"
                                : isPosFirstKind || n === 0
                                ? item.total.pos
                                : ""}
                            </div>
                            <div role="td">{item.playername}</div>
                            <div role="td">
                              {item.total.thru === 18 ? "F" : item.total.thru}
                            </div>
                            <div role="td">
                              {item.total.toPar < 0 && (
                                <span className={styles.highlight}>
                                  {item.total.toPar}
                                </span>
                              )}
                              {item.total.toPar == 0 && (
                                <span
                                  className={`${styles.highlight} ${styles.highlightPar}`}
                                >
                                  E
                                </span>
                              )}
                              {item.total.toPar > 0 && (
                                <span
                                  className={`${styles.highlight} ${styles.highlightOverPar}`}
                                >
                                  +{item.total.toPar}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {leaderboard_btn_title && leaderboard_btn_url && (
                  <Cta
                    fullSlug={leaderboard_btn_url}
                    name={leaderboard_btn_title}
                    className={styles.headerBtn}
                  />
                )}
              </div>
            )}
          </motion.div>
        </GridElement>
      </GridWrapper>
    </div>
  );
};

export default Header;
