import Image from "next/image";
import dayjs from "dayjs";
import styles from "./TournamentCard.module.css";
import { useWindowSize } from "../../hooks/useWindowSize";
import { motion } from "framer-motion";
import Link from "next/link";
import {
  getTotalPrize,
  isElevatedRound,
  isFreeAmateur,
  isPossibleToJoin,
  isPossibleToJoinAM,
  isPremiumAmateur,
} from "../../utils/tournaments";
import { Tournament } from "../../types/tournament";
import { useUI } from "../../context/UIContext";
import { useEffect, useState } from "react";
import Cta from "../Cta/Cta";
import { PGA_TOURNAMENT_ID } from "../../utils/constants";

interface TournamentCardProps {
  tournament: Tournament;
  isAmateur?: boolean;
}

const TournamentCard = ({ tournament, isAmateur }: TournamentCardProps) => {
  const {
    id: tournamentId,
    sponsors,
    name,
    rounds,
    startDate,
    endDate,
    participants,
    season,
    signUpStartTime,
    signUpEndTime,
    locationsConfiguration,
  } = tournament;
  const courseImg = rounds?.[0]?.course?.image?.url;
  const logo = sponsors?.[0]?.logoUri;
  const playersCount =
    participants?.allInvitationsCount -
    participants?.declinedCount -
    participants?.withdrawnCount;

  const [nextSignupUri, setNextSignupUri] = useState<string | null>("");
  const { state: uiState } = useUI();

  useEffect(() => {
    if (nextSignupUri !== window.localStorage.getItem("next_signup_uri")) {
      setNextSignupUri(window.localStorage.getItem("next_signup_uri"));
    }
  }, [uiState]);

  const { dispatch } = useUI();

  const { isDesktop, isMobile } = useWindowSize();

  const signupUri = locationsConfiguration[0].paymentUrl;

  const isElevated = isElevatedRound(season, name);
  const isFinalSeason2 = season === 2 && name?.toLowerCase()?.includes("final");
  const isFinalSeason3 = season === 3 && name?.toLowerCase()?.includes("final");

  const isPremiumAmateurRound = isPremiumAmateur(tournament);
  const isPGATournament = tournamentId === PGA_TOURNAMENT_ID;
  const tournamentHref = `${isAmateur ? "/amateur/" : "/"}tournament/${name
    ?.replace(/:/g, "")
    .replace(/ - /g, " ")
    .split(" ")
    .join("-")
    .toLowerCase()}`;
  const isPublishedTournament = tournament?.versionInfo?.state !== "DRAFT";

  const signupStart = dayjs(signUpStartTime);
  const signupEnd = dayjs(signUpEndTime);
  const currentDate = dayjs();

  return (
    <motion.div className={styles.tournamentCardWrapper}>
      <Link href={tournamentHref}>
        <a className={styles.tournamentCardLink}>
          <div className={styles.tournamentCard}>
            <motion.div
              className={styles.tournamentCardBackground}
              initial="initial"
              whileHover={isDesktop ? "hover" : "initial"}
              variants={{
                initial: { scale: 1 },
                hover: { scale: 1.05 },
              }}
              transition={{
                ease: "linear",
              }}
            >
              <div className={styles.tournamentCardImg}>
                {courseImg && (
                  <Image src={courseImg} layout="fill" objectFit="cover" />
                )}
              </div>
              <div className={styles.tournamentCardContent}>
                <motion.div
                  className={styles.tournamentCardHeader}
                  initial={{
                    transform: isMobile
                      ? "translate(0, 2rem)"
                      : "translate(0, 0)",
                  }}
                  whileInView={{
                    transform: "translate(0, 0)",
                  }}
                  transition={{
                    ease: "linear",
                    duration: 0.8,
                  }}
                >
                  {!!logo ? (
                    <div className={styles.tournamentCardLogoWrapper}>
                      <div className={styles.tournamentLogo}>
                        <Image src={logo} layout="fill" objectFit="contain" />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.tournamentCardLogo} />
                  )}
                  <h1 className={styles.tournamentCardTitle}>{name}</h1>
                </motion.div>
                <div className={styles.tournamentCardBoxes}>
                  <div className={styles.tournamentCardBox}>
                    {!isAmateur && isElevated && (
                      <div
                        className={styles.tournamentCardArrowsBg}
                        style={{
                          background: `url("/bg_graphics/arrows_on_off_pro.svg") no-repeat`,
                        }}
                      ></div>
                    )}
                    {isAmateur && isPremiumAmateurRound && (
                      <div
                        className={styles.tournamentCardArrowsBg}
                        style={{
                          background: `url("/bg_graphics/arrows_on_off_amateur.svg") no-repeat`,
                        }}
                      ></div>
                    )}
                    <h1 className={styles.cardBoxTitle}>
                      {isAmateur ? "Entry fee" : "Purse"}
                    </h1>
                    <h2 className={styles.cardBoxText}>
                      {isAmateur
                        ? isFreeAmateur(tournament)
                          ? "Free"
                          : "$10"
                        : `$${getTotalPrize(
                            playersCount,
                            isElevated,
                            isFinalSeason2,
                            isPGATournament,
                            isFinalSeason3
                          ).toLocaleString("en-US")}`}
                    </h2>
                  </div>
                  <div className={styles.tournamentCardBox}>
                    <h1 className={styles.cardBoxTitle}>Players</h1>
                    <h2 className={styles.cardBoxText}>
                      {isFinalSeason2 ? 100 : `${playersCount} / 1000`}
                    </h2>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </a>
      </Link>
      <div className={styles.tournamentInfo}>
        <h2 className={styles.cardBoxText}>
          {dayjs(startDate).format("MMM D")} - {dayjs(endDate).format("MMM D")}
        </h2>
        {signUpStartTime &&
          signUpEndTime &&
          currentDate.isAfter(signupStart) &&
          currentDate.isBefore(signupEnd) &&
          isPossibleToJoin(playersCount) &&
          !isAmateur &&
          signupUri &&
          !isPGATournament && (
            <Cta
              name="Join"
              className={`${styles.cardBoxText} ${styles.cta}`}
              onClick={() => {
                window.localStorage.setItem("next_signup_uri", signupUri);
                dispatch({
                  type: "toggleJoinFlowInfoModal",
                  payload: { isOpen: true, title: name, modalType: "signup" },
                });
              }}
            />
          )}
        {isAmateur && isPossibleToJoinAM(endDate, isPublishedTournament) && (
          <Cta
            name="Join"
            className={`${styles.cardBoxText} ${styles.cta}`}
            href={`https://portal.trackmangolf.com/player/tournaments/${tournamentId}`}
          />
        )}
      </div>
    </motion.div>
  );
};

export default TournamentCard;
