import { useState } from "react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import dynamic from "next/dynamic";
import { PortalWithStateProps } from "react-portal";
const PortalWithState = dynamic<PortalWithStateProps>(() =>
  import("react-portal").then((module) => module.PortalWithState)
);
const ReactPlayer = dynamic(() => import("react-player"));
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
const CancelIcon = dynamic(() => import("@mui/icons-material/Cancel"));
import { sbBlok } from "../../types/sb-types";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import styles from "./VideoComponent.module.css";
import { useWindowSize } from "../../hooks/useWindowSize";
import Cta from "../Cta";
import { NODE_PARAGRAPH, render } from "storyblok-rich-text-react-renderer";

interface VideoComponentProps {
  blok: sbBlok;
}

const VideoComponent = ({ blok }: VideoComponentProps) => {
  const { video_thumbnail, video_source, is_portrait } = blok;
  const { windowSize } = useWindowSize();
  const [showVideo, setShowVideo] = useState(false);

  return (
    <section {...storyblokEditable(blok)}>
      <GridWrapper
        padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
        padding_bottom={
          blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
        }
      >
        <GridElement
          gridLayout={[
            [0, 1, 4, 2],
            [961, 2, 8, 1],
            [1401, 5, 13, 1],
          ]}
        >
          {windowSize.width < 961 ? (
            <div
              className={`${styles.videoWrapper}${
                is_portrait ? " " + styles.videoWrapperPortrait : ""
              }`}
            >
              {!showVideo ? (
                <>
                  <div className={styles.videoWrapperOverlay} />
                  {video_thumbnail?.filename && (
                    <Image
                      onClick={() => setShowVideo(true)}
                      src={video_thumbnail?.filename}
                      sizes="100vw"
                      alt={video_thumbnail?.alt}
                      layout="fill"
                      objectFit="cover"
                    />
                  )}
                  <PlayArrowIcon
                    className={styles.playButton}
                    style={{
                      fontSize: 72,
                    }}
                    onClick={() => setShowVideo(true)}
                  />
                </>
              ) : (
                <ReactPlayer
                  url={video_source}
                  playing
                  playsinline
                  controls
                  width="100%"
                  height="100%"
                  config={{
                    vimeo: {
                      playerOptions: {
                        autoplay: true,
                        playsinline: true,
                      },
                    },
                  }}
                />
              )}
            </div>
          ) : (
            <PortalWithState closeOnOutsideClick closeOnEsc>
              {({ openPortal, closePortal, isOpen, portal }) => {
                if (typeof document !== "undefined") {
                  if (isOpen) {
                    document.body.style.overflow = "hidden";
                  } else {
                    document.body.style.overflow = "auto";
                  }
                }
                return (
                  <div className={styles.videoWrapper}>
                    <div className={styles.videoWrapperOverlay} />
                    {video_thumbnail?.filename && (
                      <Image
                        onClick={(e) => {
                          openPortal(e);
                          setShowVideo(true);
                        }}
                        src={video_thumbnail?.filename}
                        sizes="100vw"
                        alt={video_thumbnail?.alt}
                        layout="fill"
                        objectFit="cover"
                      />
                    )}
                    {!isOpen && (
                      <PlayArrowIcon
                        className={styles.playButton}
                        style={{
                          fontSize: 92,
                        }}
                        onClick={(e) => {
                          openPortal(e);
                          setShowVideo(true);
                        }}
                      />
                    )}
                    {portal(
                      <>
                        <div
                          className={styles.portalOverlay}
                          onClick={() => {
                            closePortal();
                            setShowVideo(false);
                          }}
                        />
                        <div className={styles.portal}>
                          <CancelIcon
                            className={styles.closeIcon}
                            style={{
                              fontSize: 36,
                            }}
                            onClick={() => {
                              closePortal();
                              setShowVideo(false);
                            }}
                          />
                          <ReactPlayer
                            url={video_source}
                            playing
                            controls
                            width="100%"
                            height="auto"
                            config={{
                              vimeo: {
                                playerOptions: {
                                  playsinline: true,
                                },
                              },
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                );
              }}
            </PortalWithState>
          )}
        </GridElement>
        <GridElement
          className={styles.videoGridContent}
          gridLayout={[
            [0, 1, 4, 1],
            [961, 11, 5, 1],
            [1401, 20, 8, 1],
          ]}
        >
          <div className={styles.contentWrapper}>
            {blok.title && (
              <h1 className={styles.contentTitle}>{blok.title}</h1>
            )}
            {blok.content_text &&
              render(blok.content_text, {
                nodeResolvers: {
                  [NODE_PARAGRAPH]: (children) => <p>{children}</p>,
                },
                blokResolvers: {
                  ["cta_button"]: (props: any) => {
                    return (
                      <Cta
                        key={props?._uid}
                        name={
                          props?.name?.length > 0
                            ? props?.name
                            : props?.link?.story?.name
                        }
                        href={props?.link?.url}
                        fullSlug={props?.link?.story?.full_slug}
                      />
                    );
                  },
                },
              })}
            {blok.content.length > 0 &&
              blok.content.map((c: sbBlok) => {
                if (c.component === "paragraph") {
                  return <p key={c._uid}>{c.content}</p>;
                }
                if (c.component === "cta_button") {
                  return (
                    <Cta
                      className={styles.cta}
                      key={c._uid}
                      name={c.name.length > 0 ? c.name : c.link.story?.name}
                      href={c.link?.url}
                      fullSlug={c.link?.story?.full_slug}
                    />
                  );
                }
              })}
          </div>
        </GridElement>
      </GridWrapper>
    </section>
  );
};

export default VideoComponent;
