import dayjs from "dayjs";
import {
  ENTRY_FEE,
  ENTRY_FEE_PGA,
  FIRST_PLACE_PRICE,
  FIRST_PLACE_PRICE_ELEVATED,
  INITIAL_PURSE,
  INITIAL_PURSE_SEASON_3_FINAL,
  INITIAL_PURSE_ELEVATED,
  INITIAL_PURSE_PGA,
  TOURNAMENT_ID_SEASON_1_FINAL,
} from "./constants";
import { Tournament } from "../types/tournament";

export const isPossibleToJoin = (participants: number) => {
  return participants <= 1000;
};

export const isPossibleToJoinAM = (
  endDate: string | undefined,
  isPublished: boolean | undefined
) => {
  if (!endDate || !isPublished) return null;
  return dayjs(endDate).isAfter(new Date());
};

export const sortByStartDate = (tournaments: Array<Tournament>) => {
  return tournaments?.sort((a: Tournament, b: Tournament) =>
    dayjs(a?.startDate) >= dayjs(b?.startDate) ? 1 : -1
  );
};

export const sortTournamentsActualFirst = (tournaments: Array<Tournament>) => {
  const futureTournaments = sortByStartDate(
    tournaments?.filter((t: Tournament) => dayjs(t?.startDate).isAfter(dayjs()))
  );

  const restOfTournaments = sortByStartDate(
    tournaments?.filter(
      (t: Tournament) =>
        dayjs(t?.startDate).isBefore(dayjs()) ||
        dayjs(t?.startDate).isSame(dayjs())
    )
  );

  return futureTournaments?.concat(restOfTournaments);
};

export const getActualTournament = (tournaments: Array<Tournament>) => {
  return sortByStartDate(
    tournaments?.filter((t: Tournament) => dayjs(t?.endDate).isAfter(dayjs()))
  );
};

export const groupTournamentsBySeason = (
  tournaments: Tournament[]
): Tournament[] => {
  return (
    tournaments &&
    tournaments.map((tournament) => {
      const start = dayjs(tournament.startDate);
      const end = dayjs(tournament.endDate);

      const season1Start = dayjs("2023-01-01");
      const season1End = dayjs("2023-04-3");

      const season2Start = dayjs("2023-11-01");
      const season2End = dayjs("2024-04-3");

      // const season3Start = dayjs('2024-11-01');
      // const season3End = dayjs('2025-04-3');

      const season3Start = dayjs("2024-10-01");
      const season3End = dayjs("2025-04-3");

      if (start.isAfter(season1Start) && end.isBefore(season1End)) {
        return { ...tournament, season: 1 };
      } else if (start.isAfter(season2Start) && end.isBefore(season2End)) {
        return { ...tournament, season: 2 };
      } else if (start.isAfter(season3Start) && end.isBefore(season3End)) {
        return { ...tournament, season: 3 };
      } else {
        return { ...tournament, season: 0 };
      }
    })
  );
};

export const getTotalPrize = (
  playersCount: number,
  elevatedRound?: boolean,
  isFinalSeason2?: boolean,
  isPGA?: boolean,
  isFinalSeason3?: boolean
) => {
  if (isPGA) {
    return INITIAL_PURSE_PGA + ENTRY_FEE_PGA * playersCount;
  }

  if (isFinalSeason2) return INITIAL_PURSE;

  if (isFinalSeason3) {
    return INITIAL_PURSE_SEASON_3_FINAL;
  }

  return (
    (elevatedRound ? INITIAL_PURSE_ELEVATED : INITIAL_PURSE) +
    ENTRY_FEE * playersCount
  );
};

const initialPrizeForStrokeAlgorithm = (
  elevatedRound: boolean,
  isRound456: boolean
) => {
  if (elevatedRound && !isRound456) return 70000;
  if (elevatedRound && isRound456) return 80000;
  if (isRound456) return 50000;

  return 40000;
};

//for 1 and 2 seasons
export const prizeCalculator = (
  playersCount: number,
  tournamentSeason: number,
  elevatedRound: boolean,
  isRound456: boolean
) => {
  const prizeList = [];

  if (playersCount < 10 && tournamentSeason === 2) {
    prizeList[0] = elevatedRound
      ? FIRST_PLACE_PRICE_ELEVATED
      : FIRST_PLACE_PRICE;
    return prizeList;
  }

  let prizeSum = 0;
  const RATIO = 1.3;
  const initialPrize = initialPrizeForStrokeAlgorithm(
    elevatedRound,
    isRound456
  );

  const totalPrize = initialPrize + ENTRY_FEE * playersCount;
  const remainder =
    totalPrize - (100 + 150 + 200 + 250 + 300 + 350) * playersCount * 0.05;

  for (let i = 0; i < playersCount; i++) {
    const RANK = i + 1;
    prizeList[i] = Math.round(
      ((RATIO - 1) * remainder) / Math.pow(RATIO, RANK)
    );
    const percentile = RANK / playersCount;
    if (percentile <= 0.05) prizeList[i] += 350;
    if (percentile > 0.05 && percentile <= 0.1) prizeList[i] += 300;
    if (percentile > 0.1 && percentile <= 0.15) prizeList[i] += 250;
    if (percentile > 0.15 && percentile <= 0.2) prizeList[i] += 200;
    if (percentile > 0.2 && percentile <= 0.25) prizeList[i] += 150;
    if (percentile > 0.25 && percentile <= 0.3) prizeList[i] += 100;
    if (percentile > 0.3) prizeList[i] = 0;
    if (i > 0 && prizeList[i] > 0) {
      prizeSum += prizeList[i];
    }
  }

  prizeList[0] = totalPrize - prizeSum;
  tournamentSeason === 2 &&
    prizeList?.unshift(
      elevatedRound ? FIRST_PLACE_PRICE_ELEVATED : FIRST_PLACE_PRICE
    );
  return prizeList;
};

export const isFinalSeason1 = (tournamentId: string) => {
  return tournamentId === TOURNAMENT_ID_SEASON_1_FINAL;
};

export const isElevatedRound = (
  tournamentSeason: number,
  tournamentName: string
) =>
  tournamentSeason === 2 &&
  (tournamentName?.toLowerCase().includes("round 3") ||
    tournamentName?.toLowerCase().includes("round 6"));

export const isRoundNumber456 = (tournamentName: string) =>
  tournamentName?.toLowerCase().includes("round 4") ||
  tournamentName?.toLowerCase().includes("round 5") ||
  tournamentName?.toLowerCase().includes("round 6");

export const isFreeAmateur = (tournament: Tournament) =>
  tournament?.keyValues?.find((keyValue) => {
    return (
      keyValue.key === "NextTour:TournamentKind" &&
      keyValue.value.toLowerCase() === "free"
    );
  });

export const isPremiumAmateur = (tournament: Tournament) =>
  tournament?.keyValues?.find((keyValue) => {
    return (
      keyValue.key === "NextTour:TournamentKind" &&
      keyValue.value.toLowerCase() === "amateur"
    );
  });

export const isNowPlayingTournament = (
  startDate: string | undefined,
  endDate: string | undefined
) => {
  return (
    dayjs(endDate).isAfter(new Date()) &&
    (dayjs(startDate).isSame(new Date()) ||
      dayjs(startDate).isBefore(new Date()))
  );
};

// PGA 3 season
export const prizeCalculatorPGA = (playersCount: number) => {
  if (!playersCount || playersCount < 5) return [10000];

  const sideGamesReserved: number = 8400;
  const prizeScalingRatio: number = 0.25;
  const topPlayers = Math.round(playersCount * 0.1); // Top 10% of players

  const players = Array.from({ length: playersCount }, (_, index) => ({
    rank: playersCount - index, // Rank from highest to lowest
    points: 0,
    scale: 0,
    prize: 0,
  }));

  const totalPrizePool =
    50 * playersCount - 10000 - sideGamesReserved + INITIAL_PURSE_PGA; // Total money to distribute

  // Step 1: Set q(1) = 10000
  players[0].prize = 10000; // First place gets $10,000

  // Step 2: Set q(i) = 0 if i > M (initialize prizes for ranks beyond top 10%)
  for (let i = topPlayers; i < playersCount; i++) {
    players[i].prize = 0; // Players outside the top 10% receive nothing
  }

  // Step 3: Calculate points and scales for 2 <= j <= M
  let P = 0; // Total of points for players 2 to M
  for (let j = 2; j <= topPlayers; j++) {
    const scale = Math.max(1, 10 * Math.pow(1 - prizeScalingRatio, j - 2)); // Calculate scale

    players[j - 1].scale = scale; // Save scale to player
    players[j - 1].points = (topPlayers - j + 1) * scale; // Points based on rank
    P += players[j - 1].points; // Sum up points
  }

  // Step 4: Set q(j) = max(50, p(j) / P * totalPrizePool) for 2 <= j <= M
  let Q = 0; // Total of q(j)
  for (let j = 3; j <= topPlayers; j++) {
    players[j - 1].prize = Math.max(
      50,
      (players[j - 1].points / P) * totalPrizePool
    ); // Calculate prize
    Q += players[j - 1].prize; // Sum up q(j)
  }

  // Step 5: Set q(2) = totalPrizePool - Q
  players[1].prize = totalPrizePool - Q; // Adjust prize for 2nd place

  // Ensure that q(2) is at least $50
  players[1].prize = Math.max(players[1].prize, 50);

  // Calculate any needed adjustments if q(2) was set above $50 and update Q
  Q = players
    .slice(1, topPlayers)
    .reduce((sum, player) => sum + player.prize, 0);

  // Return an array of prizes
  return players.map((player) => Math.round(player.prize));
};

//for 3 seasons
export const prizeCalculatorSeason3 = (playersCount: number) => {
  const prizeList = [];

  if (playersCount < 10) {
    prizeList[0] = FIRST_PLACE_PRICE;
    return prizeList;
  }

  let prizeSum = 0;
  const RATIO = 1.3;

  const totalPrize = 50000 + ENTRY_FEE * playersCount;
  const remainder =
    totalPrize - (100 + 150 + 200 + 250 + 300 + 350) * playersCount * 0.05;

  for (let i = 0; i < playersCount; i++) {
    const RANK = i + 1;
    prizeList[i] = Math.round(
      ((RATIO - 1) * remainder) / Math.pow(RATIO, RANK)
    );
    const percentile = RANK / playersCount;
    if (percentile <= 0.05) prizeList[i] += 350;
    if (percentile > 0.05 && percentile <= 0.1) prizeList[i] += 300;
    if (percentile > 0.1 && percentile <= 0.15) prizeList[i] += 250;
    if (percentile > 0.15 && percentile <= 0.2) prizeList[i] += 200;
    if (percentile > 0.2 && percentile <= 0.25) prizeList[i] += 150;
    if (percentile > 0.25 && percentile <= 0.3) prizeList[i] += 100;
    if (percentile > 0.3) prizeList[i] = 0;
    if (i > 0 && prizeList[i] > 0) {
      prizeSum += prizeList[i];
    }
  }

  prizeList[0] = totalPrize - prizeSum;
  prizeList?.unshift(FIRST_PLACE_PRICE);
  return prizeList;
};
