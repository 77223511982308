import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Image from "next/image";
import { useState } from "react";
import { motion } from "framer-motion";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ShareIcon from "@mui/icons-material/Share";
import ScoreCardVideo from "../ScoreCardVideo/ScoreCardVideo";
import profileMan from "../../public/icons/avatar_m.svg";
import profileWoman from "../../public/icons/avatar_w.svg";
import styles from "./ScoreCard.module.css";
import { useRouter } from "next/router";
import { useWindowSize } from "../../hooks/useWindowSize";

interface ScoreCardProps {
  playerId: string;
  playerScoreCardId: string;
  shouldIntervalRefetch: boolean;
  profilePicture: string | null;
  playerName: string;
  playerHcp: string;
  playerGender: string;
  fetchData: boolean;
  isOpen?: boolean;
  setHasScorecardLoaded?: (hasLoaded: boolean) => void;
  playerMuted?: boolean;
  showVideoPlaceholder?: boolean;
  isNineHoles?: boolean;
  isAmateur?: boolean;
}

interface HoleData {
  grossScore: number;
  par: number;
  holeNumber: number;
  shots: [];
  distance: number;
}

const shareMessageVariants = {
  initial: {
    opacity: 0,
  },
  active: {
    opacity: [1, 0],
    transition: {
      duration: 3,
    },
  },
};

const ScoreCard = ({
  playerId,
  playerScoreCardId,
  shouldIntervalRefetch,
  profilePicture,
  playerName,
  playerHcp,
  playerGender,
  fetchData = false,
  isOpen = false,
  playerMuted,
  showVideoPlaceholder,
  setHasScorecardLoaded,
  isNineHoles,
  isAmateur,
}: ScoreCardProps) => {
  const { isMobile } = useWindowSize();
  const [currentHoleNumber, setCurrentHoleNumber] = useState(1);
  const [currentShotNumber, setCurrentShotNumber] = useState(1);
  const [currentVideo, setCurrentVideo] = useState<string | undefined>(
    undefined
  );
  const [linkCopied, setLinkCopied] = useState(false);

  const { asPath } = useRouter();

  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  const { data: scoreCardData, isLoading } = useQuery(
    ["player-scorecard-data", playerScoreCardId],
    () => {
      return axios.post("/api/get-scorecard", {
        variables: {
          scorecardId: playerScoreCardId,
        },
      });
    },
    {
      enabled: fetchData,
      refetchInterval: shouldIntervalRefetch && 1000 * 30, // 30s
      refetchIntervalInBackground: shouldIntervalRefetch,
      refetchOnWindowFocus: false,
      onSuccess: (scData) => {
        const getVideo = scData?.data?.data?.node?.holes[0];
        // check if initial hole is 1 for front nine or full 18 holes
        // or if playing only back nine and initial hole is 10.
        if (
          currentHoleNumber === 1 &&
          scData?.data?.data?.node?.holes[0].holeNumber !== 1
        ) {
          setCurrentHoleNumber(scData?.data?.data?.node?.holes[0].holeNumber);
        }
        if (getVideo && getVideo.shots && getVideo.shots[0].videos) {
          setCurrentVideo(getVideo.shots[0].videos[0]?.videoUrl);
        }
        if (setHasScorecardLoaded) {
          setHasScorecardLoaded(true);
        }
      },
    }
  );

  const videoData = scoreCardData?.data?.data?.node?.holes;

  const handleHoleClick = ({ holeNumber }: { holeNumber: number }) => {
    setCurrentHoleNumber(holeNumber);
    if (currentShotNumber !== 1) {
      setCurrentShotNumber(1);
    }
    const getVideo = videoData?.find(
      (video: { holeNumber: number }) => video.holeNumber === holeNumber
    );
    if (getVideo && getVideo.shots && getVideo.shots[0].videos?.length) {
      setCurrentVideo(getVideo.shots[0].videos[0].videoUrl);
    } else {
      setCurrentVideo(undefined);
    }
  };

  const ScorecardDataTable = ({ isFront }: { isFront: boolean }) => {
    const isNineHoleRound =
      isNineHoles || scoreCardData?.data?.data?.node?.holes?.length === 9;

    let holeStartIndex = 0;
    let holeEndIndex = 9;

    if (!isFront && !isNineHoleRound) {
      holeStartIndex = 9;
      holeEndIndex = 18;
    }

    let yards = 0;
    let par = 0;
    let score = 0;
    let totalYards = 0;
    let totalPar = 0;
    let totalScore = 0;

    scoreCardData?.data?.data?.node?.holes.forEach(
      (hole: HoleData, index: number) => {
        if (isFront && index < 9) {
          yards += Math.floor(hole.distance * 1.09361);
          par += hole.par;
          score += hole.grossScore;
        }
        if (!isFront && index >= 9) {
          yards += Math.floor(hole.distance * 1.09361);
          par += hole.par;
          score += hole.grossScore;
        }
        totalYards += Math.floor(hole.distance * 1.09361);
        totalPar += hole.par;
        totalScore += hole.grossScore;
      }
    );

    return (
      <div
        className={`${styles.scoreCard} ${
          isFront ? styles.scoreCardFront : styles.scoreCardBack
        }${
          isFront && isNineHoleRound ? " " + styles.scoreCardFrontNineRound : ""
        }${
          !isFront && isNineHoleRound ? " " + styles.scoreCardBackNineRound : ""
        }`}
      >
        <div
          className={`${styles.scoreCardCol} ${styles.scoreCardColHighlight} ${
            isFront ? styles.scoreCardColFront : styles.scoreCardColBack
          }`}
        >
          <div>Hole</div>
          <div>Yds</div>
          <div>Par</div>
          <div>Total</div>
        </div>
        {isLoading &&
          Array.from(Array(9)).map((_item: any, i: number) => (
            <div className={styles.scoreCardCol} key={i}>
              <div>
                <span className={styles.loading} />
              </div>
              <div>
                <span className={styles.loading} />
              </div>
              <div>
                <span className={styles.loading} />
              </div>
              <div>
                <span>
                  <span>
                    <span className={styles.loading} />
                  </span>
                </span>
              </div>
            </div>
          ))}
        {scoreCardData?.data?.data?.node?.holes
          ?.slice(holeStartIndex, holeEndIndex)
          ?.map((hole: HoleData) => {
            const legendClasses = [styles.legend];
            if (hole.grossScore && hole.grossScore === hole.par - 1) {
              legendClasses.push(styles.legendBirdie);
            }
            if (hole.grossScore && hole.grossScore < hole.par - 1) {
              legendClasses.push(styles.legendEagle);
            }
            if (hole.grossScore && hole.grossScore === hole.par + 1) {
              legendClasses.push(styles.legendBogey);
            }
            if (hole.grossScore && hole.grossScore > hole.par + 1) {
              legendClasses.push(styles.legendDBogey);
            }
            const renderLegendClasses = legendClasses.join(" ");
            return (
              <div
                className={`${styles.scoreCardCol}${
                  currentHoleNumber === hole.holeNumber
                    ? " " + styles.scoreCardColSelected
                    : ""
                }`}
                onClick={() => {
                  handleHoleClick({ holeNumber: hole.holeNumber });
                }}
                key={hole.holeNumber}
              >
                <div className={styles.scoreCardHoleNumber}>
                  <span>{hole.holeNumber}</span>
                  {hole?.shots?.find((shot: { videos: [] }) => {
                    return !!shot?.videos?.length;
                  }) && (
                    <PlayCircleOutlineIcon
                      className={styles.scorCardHoleNumberIcon}
                    />
                  )}
                </div>
                <div>{Math.floor(hole.distance * 1.09361)}</div>
                <div>{hole.par}</div>
                <div>
                  <span className={renderLegendClasses}>
                    <span>{hole.grossScore || "-"}</span>
                  </span>
                </div>
              </div>
            );
          })}
        <div
          className={`${styles.scoreCardCol} ${styles.scoreCardColHighlight}`}
        >
          <div>{isFront ? "Out" : "In"}</div>
          <div>{isLoading ? <span className={styles.loading} /> : yards}</div>
          <div>{isLoading ? <span className={styles.loading} /> : par}</div>
          <div>{isLoading ? <span className={styles.loading} /> : score}</div>
        </div>
        {isFront && !isNineHoleRound && (
          <div className={`${styles.scoreCardCol} ${styles.scoreCardColEnd}`}>
            <div />
            <div />
            <div />
            <div>
              <span>-</span>
            </div>
          </div>
        )}
        {(!isFront || isNineHoleRound) && (
          <div className={styles.scoreCardCol}>
            <div>Total</div>
            <div>
              {isLoading ? <span className={styles.loading} /> : totalYards}
            </div>
            <div>
              {isLoading ? <span className={styles.loading} /> : totalPar}
            </div>
            <div>
              {isLoading ? <span className={styles.loading} /> : totalScore}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={styles.playerProfile}>
        <div className={styles.playerDetails}>
          <div className={styles.playerPicture}>
            {profilePicture ? (
              <Image
                src={profilePicture}
                alt="Profile picture"
                layout="fill"
                objectFit="cover"
              />
            ) : (
              <Image
                src={
                  playerGender?.toLowerCase() === "female"
                    ? profileWoman
                    : profileMan
                }
                alt="Profile picture"
                layout="fill"
                objectFit="cover"
              />
            )}
          </div>

          <div
            className={`${styles.playerInfo}${
              isLoading ? " " + styles.playerInfoLoading : ""
            }`}
          >
            <h3>{playerName}</h3>
            <p>
              <span>HCP</span>
              <span>
                {parseFloat(playerHcp) < 0
                  ? `+${-1 * parseFloat(playerHcp)}`
                  : playerHcp}
              </span>
            </p>
            <p>
              Tee:
              {isLoading ? (
                <span
                  style={{ height: "1.8rem", width: "4rem" }}
                  className={styles.loading}
                />
              ) : (
                <span>{scoreCardData?.data?.data?.node?.player?.tee}</span>
              )}
            </p>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <div className={styles.shareButtonWrapper}>
            <div
              role="button"
              className={styles.scorecardButton}
              onClick={() => {
                const basePath = asPath.split("?");
                navigator.clipboard.writeText(
                  `${origin}${basePath[0]}?playerid=${playerId}`
                );
                setLinkCopied(true);
              }}
              onMouseLeave={() => setLinkCopied(false)}
            >
              <ShareIcon style={{ fontSize: isMobile ? "large" : "medium" }} />
              {!isMobile && <span>Share</span>}
            </div>
            <motion.span
              variants={shareMessageVariants}
              animate={linkCopied ? "active" : "initial"}
              initial="initial"
              exit="initial"
              className={styles.shareMessage}
              onAnimationComplete={() => {
                if (linkCopied) {
                  setLinkCopied(false);
                }
              }}
            >
              Link copied
            </motion.span>
          </div>
        </div>
      </div>

      <div className={styles.scoreCardWrapper}>
        <div className={styles.scoreCardLegend}>
          <div className={styles.legendWrapper}>
            <span className={`${styles.legend} ${styles.legendEagle}`}>
              <span />
            </span>
            <span>Eagle or better</span>
          </div>
          <div className={styles.legendWrapper}>
            <span className={`${styles.legend} ${styles.legendBirdie}`} />
            <span>Birdie</span>
          </div>
          <div className={styles.legendWrapper}>
            <span className={`${styles.legend} ${styles.legendBogey}`} />
            <span>Bogey</span>
          </div>
          <div className={styles.legendWrapper}>
            <span className={`${styles.legend} ${styles.legendDBogey}`}>
              <span />
            </span>
            <span>Double Bogey+</span>
          </div>
        </div>

        <div className={styles.scoreCardContainer}>
          <ScorecardDataTable
            isFront={scoreCardData?.data?.data?.node?.holes[0].holeNumber === 1}
          />
          {!isNineHoles && <ScorecardDataTable isFront={false} />}
        </div>
      </div>

      <div className={styles.videoContainer}>
        {!currentVideo && !isLoading && showVideoPlaceholder && !isAmateur && (
          <div className={styles.videoPlaceholder}>
            <div className={styles.videoPlaceholderContent}>
              <h2>Selected hole has no video available</h2>
            </div>
          </div>
        )}
        {!currentVideo && !isLoading && showVideoPlaceholder && isAmateur && (
          <div className={styles.videoContainerAmateur} />
        )}
        {currentVideo && (
          <ScoreCardVideo
            playerMuted={playerMuted}
            isScoreCardOpen={isOpen}
            videoData={videoData}
            currentHoleNumber={currentHoleNumber}
            currentShotNumber={currentShotNumber}
            setCurrentShotNumber={setCurrentShotNumber}
            setCurrentHoleNumber={setCurrentHoleNumber}
            setCurrentVideo={setCurrentVideo}
          />
        )}
      </div>
    </>
  );
};

export default ScoreCard;
