import styles from "./LeaderboardActionBar.module.css";
import { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import { motion } from "framer-motion";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Option } from "../Dropdown/Dropdown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TournamentQuery } from "./LeaderboardWrapper";
import Checkbox from "../Checkbox/Checkbox";
import Cta from "../Cta";
import { useDebounce } from "../../hooks/useDebounce";
import dayjs from "dayjs";

const GENDERS = [
  { value: null, label: "All" },
  { value: "MALE", label: "Men" },
  { value: "FEMALE", label: "Women" },
];

interface LeaderboardActionBarProps {
  nationalitiesOptions?: Array<{ label: string; value: string | null }>;
  defaultDropdownOption: Option;
  dropdownOptions: Option[];
  isNowPlaying: boolean;
  tournamentQuery: TournamentQuery;
  tournamentName?: string;
  onSelectChange: (i: any) => void;
  onApplyFilters: (i: any) => void;
  isCTPLeaderboard: boolean;
  ctpDefaultDropdownOption: Option;
  ctpDropdownOptions: Option[];
  onCTPSelectChange: (i: any) => void;
  startDate: string;
}

const LeaderboardActionBar = ({
  nationalitiesOptions,
  defaultDropdownOption,
  dropdownOptions,
  isNowPlaying,
  tournamentQuery,
  tournamentName,
  onSelectChange,
  onApplyFilters,
  isCTPLeaderboard,
  ctpDefaultDropdownOption,
  ctpDropdownOptions,
  onCTPSelectChange,
  startDate,
}: LeaderboardActionBarProps) => {
  const { windowSize } = useWindowSize();
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [gender, setGender] = useState<{ value: string | null; label: string }>(
    { value: null, label: "All" }
  );
  const [playNow, setPlayNow] = useState(false);
  const [nationalities, setNationalities] = useState<
    Array<{
      value: string | null;
      label: string;
    }>
  >([{ value: null, label: "All" }]);

  useEffect(() => {
    const htmlElement = document.documentElement;
    const bodyElement = document.body;

    if (htmlElement && bodyElement) {
      if (isOpen) {
        htmlElement.style.overflow = "hidden";
        bodyElement.style.overflow = "hidden";
      } else {
        htmlElement.style.overflow = "";
        bodyElement.style.overflow = "";
      }
    }

    return () => {
      if (htmlElement && bodyElement) {
        htmlElement.style.overflow = "";
        bodyElement.style.overflow = "";
      }
    };
  }, [isOpen]);

  const handlePlayNowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setPlayNow(!playNow);

    onApplyFilters({
      playNow: isChecked || null,
    });

    if (isChecked) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "checkNowPlaying",
        playNow: playNow,
        tournamentName: tournamentName || "",
      });
    }
  };

  const handleGenderChange = ({
    label,
    value,
  }: {
    label: string;
    value: string | null;
  }) => {
    if (gender.label !== label) {
      setGender({ value, label });
    }
  };

  const handleNationalityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setNationalities((prevNationalities: any) => [
        ...prevNationalities,
        {
          value: newValue,
          label: nationalitiesOptions?.find(
            (option) => option?.value === newValue
          )?.label,
        },
      ]);
    } else {
      setNationalities((prevNationalities) =>
        prevNationalities.filter((n) => n.value !== newValue)
      );
    }
  };

  const onClear = () => {
    setSearchText("");
    setPlayNow(false);
    setGender({ value: null, label: "All" });
    setNationalities([{ value: null, label: "All" }]);

    onApplyFilters({
      playNow: null,
      nationality: [],
      searchText: "",
      ...(tournamentQuery !== "longestDrive" && { gender: null }),
    });
    setAppliedFiltersCount(0);
  };

  const onApply = () => {
    onApplyFilters({
      playNow: playNow || null,
      nationality: nationalities
        .filter((i) => i.value !== null)
        ?.map((i) => i.value),
      searchText: "",
      ...(tournamentQuery !== "longestDrive" && { gender: gender.value }),
    });
    setSearchText("");
    setIsOpen(false);

    countAndSetAppliedFilters();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "applyFilter",
      tournamentName: tournamentName || "",
    });
  };

  const countAndSetAppliedFilters = () => {
    let filtersCount = 0;
    const selectedNationalitiesCount = nationalities.filter(
      (n) => n.value !== null
    ).length;

    if (gender.value !== null) {
      filtersCount++;
    }
    filtersCount += selectedNationalitiesCount;
    setAppliedFiltersCount(filtersCount);
  };

  const filterPanelVariants = {
    initial: {
      opacity: 0,
      x: !(windowSize.width < 961) ? "100%" : 0,
      y: windowSize.width < 961 ? "100%" : 0,
    },
    open: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  };

  const backgroundVariants = {
    initial: {
      display: "none",
      opacity: 0,
    },
    open: {
      display: "block",
      opacity: 0.7,
    },
  };

  const debouncedSearchText = useDebounce(searchText, 300);

  useEffect(() => {
    onApplyFilters({ searchText: debouncedSearchText });

    if (debouncedSearchText) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "search",
        searchText: debouncedSearchText,
        tournamentName: tournamentName || "",
      });
    }
  }, [debouncedSearchText]);

  const handleSearchInputChange = (event: { target: { value: any } }) => {
    const searchText = event.target.value;
    setSearchText(searchText);
  };

  return (
    <>
      <div className={styles.leaderboardControls}>
        <div className={styles.playNowCheckbox}>
          <Checkbox
            disabled={!isNowPlaying || tournamentQuery !== "leaderboard"}
            label="Now playing"
            isChecked={playNow}
            onChange={handlePlayNowChange}
          />
        </div>
        <div className={styles.actionBar}>
          <div className={styles.selectWrapper}>
            <Dropdown
              defaultValue={defaultDropdownOption}
              options={dropdownOptions}
              onChange={(e: any) => {
                setSearchText("");
                onSelectChange(e);
                setPlayNow(false);
                setGender({ value: null, label: "All" });
                setNationalities([{ value: null, label: "All" }]);
              }}
            />
          </div>
          {isCTPLeaderboard && (
            <Dropdown
              className={styles.ctpDropdown}
              defaultValue={ctpDefaultDropdownOption}
              options={ctpDropdownOptions}
              onChange={(e: any) => {
                onCTPSelectChange(e);
                setSearchText("");
                setPlayNow(false);
                setGender({ value: null, label: "All" });
                setNationalities([{ value: null, label: "All" }]);
              }}
            />
          )}
          <div className={styles.searchFilter}>
            <div className={styles.searchWrapper}>
              <input
                type="search"
                placeholder="Search for player"
                value={searchText}
                onChange={handleSearchInputChange}
              />
              <div className={styles.icon}>
                <SearchIcon fontSize="large" />
              </div>
            </div>
            <div className={styles.filterBtnWrapper}>
              <div
                className={styles.filterWrapper}
                onClick={() => {
                  setIsOpen(true);

                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "clickFilter",
                    tournamentName: tournamentName || "",
                  });
                }}
              >
                <div>
                  {appliedFiltersCount > 0 && (
                    <span>[ {appliedFiltersCount} ] </span>
                  )}
                  <span>Filter</span>
                </div>
                <div className={styles.icon}>
                  <TuneIcon fontSize="large" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <motion.div
          variants={backgroundVariants}
          animate={isOpen ? "open" : "initial"}
          onClick={() => setIsOpen(false)}
          transition={{ duration: 0.3 }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "black",
            zIndex: 1,
          }}
        />
        <motion.div
          id="filterPanel"
          className={styles.filterPanel}
          variants={filterPanelVariants}
          animate={isOpen ? "open" : "initial"}
          transition={{ duration: 0.4 }}
        >
          <div className={styles.filterPanelContainer}>
            <div className={styles.filterHeader}>
              {windowSize.width < 961 ? (
                <div
                  className={styles.filterHeaderIcon}
                  onClick={() => setIsOpen(false)}
                >
                  <KeyboardArrowDownIcon fontSize="large" />
                </div>
              ) : (
                <>
                  <span>Filter</span>
                  <div
                    className={styles.filterHeaderIcon}
                    onClick={() => setIsOpen(false)}
                  >
                    <CloseIcon fontSize="large" />
                  </div>
                </>
              )}
            </div>

            <div className={styles.filterContent}>
              <div>
                <span className={styles.filterGroupTitle}>Gender</span>
                <div className={styles.leaderboardFilters}>
                  {GENDERS.map(
                    (option: { label: string; value: string | null }) => {
                      return (
                        <Checkbox
                          key={option?.value}
                          disabled={
                            tournamentQuery === "longestDrive" ||
                            // check if tournament startDate is AFTER current time
                            // as not all data from API is available before tournament start
                            dayjs(startDate).isAfter(dayjs())
                          }
                          label={option?.label}
                          value={option?.value}
                          isChecked={gender.value === option.value}
                          onChange={() => handleGenderChange(option)}
                        />
                      );
                    }
                  )}
                </div>
              </div>

              <div className={styles.nationalitiesFilter}>
                <span className={styles.filterGroupTitle}>Nationality</span>
                <div className={styles.leaderboardFilters}>
                  {nationalitiesOptions?.map(
                    (option: { label: string; value: string | null }) => {
                      return (
                        <Checkbox
                          key={option?.value}
                          label={option?.label}
                          value={option?.value}
                          isChecked={nationalities.some(
                            (n) => n.value === option.value
                          )}
                          onChange={handleNationalityChange}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.filterPanelBtns}>
            <Cta variant="secondary" name="Clear" onClick={onClear} />
            <Cta name="Apply" onClick={onApply} />
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default LeaderboardActionBar;
