import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { sbBlok } from "../../types/sb-types";
import { useWindowSize } from "../../hooks/useWindowSize";
import Cta from "../Cta";
import GridElement from "../GridElement";
import GridWrapper from "../GridWrapper";
import bgArrows from "../../public/bg_graphics/arrows_in.svg";
import styles from "./TextWithMedia.module.css";
import {
  NODE_PARAGRAPH,
  NODE_UL,
  NODE_OL,
  render,
} from "storyblok-rich-text-react-renderer";

interface TextWithMediaProps {
  blok: sbBlok;
}

const TextWithMedia = ({ blok }: TextWithMediaProps) => {
  const {
    title,
    image,
    image_mobile,
    content,
    content_text,
    layout_reverse,
    call_to_action,
    show_bg_graphics,
    bg_reverse,
    above_fold,
  } = blok;
  const cta = call_to_action[0] || null;

  const mediaClasses = [styles.media];
  if (layout_reverse) mediaClasses.push(styles.mediaReverse);
  const renderMediaClasses = mediaClasses.join(" ");

  const { windowSize } = useWindowSize();

  return (
    <section {...storyblokEditable(blok)} className={styles.textWithMedia}>
      {show_bg_graphics && (
        <GridWrapper
          className={styles.bgGraphic}
          padding_top={
            blok.spacing_top?.length > 0 && blok.spacing_top[0].option
          }
          padding_bottom={
            blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
          }
        >
          <GridElement
            gridLayout={
              bg_reverse
                ? [
                    [961, 1, 8],
                    [1401, 1, 16],
                  ]
                : [
                    [961, 8, 8],
                    [1401, 14, 16],
                  ]
            }
          >
            <div style={{ paddingTop: "100%", position: "relative" }}>
              <Image
                src={bgArrows}
                alt="background arrows"
                layout="fill"
                objectFit="contain"
                priority={above_fold}
              />
            </div>
          </GridElement>
        </GridWrapper>
      )}
      <GridWrapper
        padding_top={blok.spacing_top?.length > 0 && blok.spacing_top[0].option}
        padding_bottom={
          blok.spacing_bottom?.length > 0 && blok.spacing_bottom[0].option
        }
      >
        <div className={renderMediaClasses}>
          {windowSize.width < 701 && image_mobile?.filename ? (
            <Image
              src={image_mobile.filename}
              alt={image_mobile.alt}
              layout="fill"
              objectFit="cover"
              priority={above_fold}
              sizes="(max-width: 960px) 100vw"
            />
          ) : (
            <Image
              src={image.filename}
              alt={image.alt}
              layout="responsive"
              width={100}
              height={100}
              objectFit="cover"
              priority={above_fold}
              sizes="(max-width: 960px) 100vw, (min-width: 961) 60vw"
              quality={100}
            />
          )}
        </div>
        <div
          className={`${styles.content}${
            layout_reverse ? " " + styles.contentReverse : ""
          }`}
        >
          {title?.content?.length > 0 && (
            <div className={styles.textWithMediaTitle}>
              {render(title, {
                nodeResolvers: {
                  [NODE_PARAGRAPH]: (children) => <h2>{children}</h2>,
                },
              })}
            </div>
          )}
          {content_text &&
            render(content_text, {
              nodeResolvers: {
                [NODE_PARAGRAPH]: (children) => <p>{children}</p>,
                [NODE_UL]: (children) => <ul>{children}</ul>,
                [NODE_OL]: (children) => <ol>{children}</ol>,
              },
            })}
          {content.map((c: sbBlok) => {
            if (c.component === "paragraph") {
              return <p key={c._uid}>{c.content}</p>;
            }
            if (c.component === "list") {
              return (
                <ul key={c._uid}>
                  {c.content.map((i: sbBlok) => (
                    <li key={i._uid}>
                      <span>{i.label}</span>
                      <span>{i.content}</span>
                    </li>
                  ))}
                </ul>
              );
            }
          })}
          {cta && (
            <Cta
              href={cta.link.url}
              fullSlug={cta.link?.story?.full_slug}
              name={cta.name}
              style={{ marginTop: "2rem" }}
            />
          )}
        </div>
      </GridWrapper>
    </section>
  );
};

export default TextWithMedia;
